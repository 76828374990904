/* src/components/Background.css */

.background {
    position: fixed; /* Position fixed to cover the entire viewport */
    top: 0;
    left: 0;
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    background-color: #ae7ad9; /* Set your desired background color */
    overflow: hidden; /* Hide overflow */
    z-index: -1; /* Ensure it stays behind other content */
  }
  
  .background-element {
    position: absolute;
    animation: float 5s infinite ease-in-out;
  }
  
  .tree {
    background-image: url('../assets/images/tree.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 100px; /* Adjust size as needed */
    height: 100px; /* Adjust size as needed */
    top: 10%; /* Position relative to the page */
    left: 10%;
  }
  
  .tree {
    background-image: url('../assets/images/tree.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 80px; /* Adjust size as needed */
    height: 80px; /* Adjust size as needed */
    top: 70%;
    left: 70%;
  }
  
  
  @keyframes float {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  